import { createApp } from 'vue';
import { setupCalendar } from 'v-calendar';
import App from './App.vue';
import AlgoliaApp from './AlgoliaApp.vue';

// App main
const main = async () => {
    // Create our vue instance
    const algoliaApp = createApp(AlgoliaApp);
    const root = algoliaApp.mount('#algoliaContainer');

    const app2 = createApp(App);
    app2.use(setupCalendar, {});
    const root2 = app2.mount('#componentContainer');
    const root3 = app2.mount('#componentContainer2');
    return { root, root2, root3 };
};

// Execute async function
main().then( (root) => {
});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}
