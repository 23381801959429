<script>
// Async load the Vue 3 APIs we need from the VUE ESM
import { defineAsyncComponent, defineComponent } from 'vue/dist/vue.esm-bundler';

export default defineComponent({
    components: {
        findacoach: defineAsyncComponent(() =>
            import(/* webpackChunkName: "findacoach" */ "./modules/FindACoach.vue")
        ),
        findresources: defineAsyncComponent(() =>
            import(/* webpackChunkName: "findresources" */ "./modules/FindResources.vue")
        ),
        events: defineAsyncComponent(() =>
            import(/* webpackChunkName: "events" */ "./modules/events/Events.vue")
        ),
        eventsfeatured: defineAsyncComponent(() =>
            import(/* webpackChunkName: "eventsfeatured" */ "./modules/events/EventsFeatured.vue")
        ),
        eventssearchbar: defineAsyncComponent(() =>
            import(/* webpackChunkName: "eventssearchbar" */ "./modules/events/EventsSearchBar.vue")
        )
    },
    data: () => ({
    }),
    methods: {
    },
    created(){
    },
    async mounted() {
    }
})

// Example component
// 'hero': defineAsyncComponent(() => import(/* webpackChunkName: "hero" */ '@/vue/components/Hero.vue'))
</script>
