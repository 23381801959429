<script>
// Async load the Vue 3 APIs we need from the VUE ESM
import { defineAsyncComponent, defineComponent } from 'vue/dist/vue.esm-bundler';

export default defineComponent({
    components: {
        algolia: defineAsyncComponent(() =>
            import(/* webpackChunkName: "algolia" */ "./modules/Algolia.vue")
        )
    },
    data: () => ({
    }),
    methods: {
    },
    created(){
    },
    async mounted() {
    }
})
</script>
